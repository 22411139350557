/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State, namespace} from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
import { SexMasterClass } from '@/store/sexMasterClass/types'

const customer = namespace('customer');
const sexMasterClass = namespace('sexMasterClass');

export default class SexMasterClassesVideoChosenPage extends Vue {
	@customer.State('customer') customer: CustomerState | undefined;
	@sexMasterClass.State('sexMasterClass') sexMasterClass: SexMasterClass | undefined;
	@sexMasterClass.Action('getPriceVideo') getPriceVideo: any;
	@sexMasterClass.Action('payForVideo') payForVideo: any;
	@sexMasterClass.Getter('getMasterClassPriceVideo') getMasterClassPriceVideo: any;
	@customer.Getter('getAccountMoney') getAccountMoney: any;

	payVideo() {
		this.payForVideo({
			pay: this.getMasterClassPriceVideo
		})
	}

	mounted() {
		this.getPriceVideo()
	}
}
